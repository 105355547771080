
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.usp {
  width: 100%;

  &__title {
    margin-bottom: 1rem;
    line-height: 2.375rem;
    font-size: 1.75rem;
    font-family: $vc-font-headings;
  }

  &__blockList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1.25rem;
    font-size: 0.875rem;

    &__icon {
      display: block;
      width: 2.5rem;
      height: 2.5rem;
      margin-bottom: 0.5rem;
    }

    &__title {
      margin-bottom: 0.25rem;
      line-height: 1.5;
      font-size: 1rem;
      font-weight: 500;
      text-decoration: underline;
    }

    &__link {
      text-decoration: underline;
      color: $vc-color-g60;
      line-height: 1.3125rem;
      margin-top: 0.25rem;
    }
  }

  &--slider {
    background-color: white;
  }
}

// slider behavior for mobile screen
@include respond-to($sm-down) {
  .usp--slider {
    padding: 1.25rem 0 0 1.25rem;

    .usp {
      &__title {
        padding-right: 1.25rem;
      }

      &__blockContainer {
        width: 100%;
        padding-bottom: 1.25rem;
        overflow-x: auto;
      }

      &__blockList {
        flex-wrap: nowrap;
        width: min-content;
      }

      &__block {
        flex: 1;
        width: 11.875rem;
        margin-right: 1rem;
      }
    }
  }

  .usp:not(.usp--slider) {
    padding: 2rem 0;

    .usp {
      &__blockList {
        display: grid;
        row-gap: 1.25rem;
        column-gap: 1rem;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@include respond-to($md-up) {
  .usp {
    padding: 2.5rem 0;

    &--slider {
      padding: 1.5rem;
    }

    &__title {
      margin-bottom: 1.5rem;
      font-size: 2.5rem;
      line-height: 3rem;
    }

    &__blockList {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 2.5rem;
    }

    &__block {
      &__icon {
        width: 3rem;
        height: 3rem;
        margin-bottom: 0.75rem;
      }

      &__title {
        margin-bottom: 0.5rem;
      }
    }
  }
}
