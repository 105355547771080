
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.i18nChoices {
  &__innerContainer {
    font-family: HelveticaNowText, Helvetica, sans-serif;
  }
  &__modalTitle {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2rem;
    display: block;
    color: $gray90;
    margin-bottom: 0.5rem;
  }
  &__modalAction {
    margin-top: 20px;
    text-align: right;
  }
  &__gridContainer {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
  }
  &__modalHelpText {
    margin-top: 0.75rem;
  }
  &__country {
    &__selectedFlag {
      position: absolute;
      left: 0.75rem;
      top: 50%;
      height: 1rem;
      transform: translateY(-50%);
      border-radius: 50%;
    }
    &__optionItem {
      display: inline-flex;
      align-items: center;
    }
    &__optionFlag {
      height: 1.25rem;
      width: 1.25rem;
      margin-right: 0.6rem;
      border-radius: 50%;
    }
  }
  &__gridContainer {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
  }
  &__modalSubtitle {
    margin-bottom: 1rem;
    color: $gray80;
  }

  &__select {
    &__label {
      text-transform: capitalize;
      font-size: 0.75rem;
      line-height: 1.25rem;
      font-weight: 400;
      color: $gray60;
    }
  }
}

.i18n__modalCol {
  margin-bottom: 0.75rem;
}

@include respond-to($md-up) {
  .i18nChoices {
    &__select__label {
      font-size: 0.875rem;
    }
  }
}
