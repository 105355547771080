
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.stripe-banner {
  &__container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $gray10;
  }

  &__content {
    width: 90%;
    margin: 1.5rem;
  }

  &__title {
    margin-bottom: 0.75rem;
    color: $gray90;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &-flag {
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }

  &__info {
    color: $gray80;
    font-size: 1rem;
  }

  &__closeButton {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    color: white;
    background-color: $dark-black;
    width: 100%;
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    margin: 1rem auto;
    border-radius: 2px;
  }

  &__changeButton {
    border: none;
    text-decoration: underline;
    color: $gray90;
    font-size: 1rem;
  }
}

@include respond-to($md-up) {
  .stripe-banner {
    &__content {
      width: 30rem;
      margin: 2rem auto;
    }
  }
}


