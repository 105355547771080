
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1.5rem;

  &__title {
    margin-bottom: 0.5rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1.5rem;

    a {
      margin-right: 0.5rem;
    }
  }
}

.mostTrendingContainer {
  padding: 1.5rem;

  &__list {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin: 1rem 0;
    overflow-x: auto;

    &__item {
      &:not(:first-child) {
        margin-left: 1rem;
      }

      &__img {
        background-color: #e1e1e1;
      }

      &__title {
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        margin-top: 0.75rem;
      }
    }
  }
}

.notFound {
  &__greyLine {
    background-color: $vc-color-g1;
  }
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1.5rem;

  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 1.25rem;
  }

  &__information {
    color: $vc-color-g60;
    margin-bottom: 1.5rem;
  }
}

@include respond-to($md-up) {
  .notFoundContainer {
    padding: 4rem 0;
  }

  .mostTrendingContainer {
    padding: 2.5rem 1.5rem;

    &__list {
      justify-content: space-between;
    }
  }

  .uspContainer {
    justify-content: space-between;

    &__item {
      max-width: 22%;

      &__icon {
        margin-bottom: 0.75rem;
        width: 2.75rem;
        height: 2.75rem;
      }
    }
  }
}
